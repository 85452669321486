import React from 'react';

import CoreSettingsIcon from '@atlaskit/icon/core/settings';
import { token } from '@atlaskit/tokens';

export const SettingsIcon = ({ isSelected }: { isSelected: boolean }) => (
	<CoreSettingsIcon
		label=""
		spacing="spacious"
		color={isSelected ? token('color.icon.selected') : token('color.icon')}
	/>
);
